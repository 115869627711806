import React from 'react';
import Layout from '../../containers/gatsby/layout';
import Credibility from '../../containers/sections/Credibility/Credibility';
import ServiceBlurb from '../../containers/services/ServiceBlurb/ServiceBlurb';
import ServiceFAQs from '../../containers/services/ServiceFAQs/ServiceFAQs';
import BookOnlineContainer from '../../containers/sections/BookOnline/BookOnlineContainer';
import ServicesGallery from '../../containers/services/ServicesGallery/ServicesGallery';
import SEO from '../../containers/gatsby/seo';
import ServiceHeroContainer from '../../containers/heroes/ServiceHero/ServiceHeroContainer';

const PianoMoversPage = (props) => (
  <Layout overlayHeader={true} location={props.location}>
    <SEO title="Expert, Affordable Piano Movers" keywords={[`move piano service`, `man and van piano movers`, `lift and move piano london`, `piano removals`, `cheap piano movers`, `how do I move a piano`, `get a piano moved`, `transport piano london`]} />
    <ServiceHeroContainer
      jobName={'Piano Move'}
      title={'Piano Movers'}
      description={'We\'ve done this before. Get a professional piano mover with the right tools to lift and transport your piano or electronic equipment today'}
      heroImg={require('../../assets/services-heroes/piano.jpg')}
      reviewImage={require('../../assets/avatars/3.png')}
      reviewContent={'The best. We had Yassine as our driver and he was very helpful. Excellent service. Availability is superb and the rates are fair. The van is in good quality condition and the driver was well presented professional. Got everything done on time.'}
      reviewName={'Sara Bat'}
    />
    <Credibility />
    <ServiceBlurb
      blurbTitle={'Move a piano safely with JJD'}
      blurb={'Please describe your piano before you book so that we can transport it safely. We\'ve got piano covers and moving tools for Grand Pianos, Upright Pianos and Digital Pianos.'}
      blurb2={'One of our regular customers is a music band that needs a reliable man-and-van service to help move all of their drums, speakers, guitars, and other instruments safely after they\'ve finished a gig!'}
      blurb3={'Moving pianos or other expensive equipment can be a tricky task, but it doesn\'t have to be a bank breaker. Beware of companies that tell you it\'s extremely difficult and therefore an expensive process. They\'re probably haggling you, and taking that extra premium for themselves instead the moving professional.'}
    />
    <ServicesGallery
      gallerySubtitle={'Safely transport your piano door-to-door with JJD'}
      photos={[
        require('../../assets/service-gallery/1.jpg'),
        require('../../assets/service-gallery/2.png'),
        require('../../assets/service-gallery/3.jpg'),
        require('../../assets/service-gallery/4.jpg'),
        require('../../assets/service-gallery/5.jpg'),
        require('../../assets/service-gallery/6.jpg'),
        require('../../assets/service-gallery/8.jpg'),
        require('../../assets/service-gallery/9.png'),
        require('../../assets/service-gallery/10.png'),
        require('../../assets/service-gallery/11.jpg'),
        require('../../assets/service-gallery/12.jpg'),
        require('../../assets/service-gallery/13.jpg'),
        require('../../assets/service-gallery/14.jpg'),
        require('../../assets/service-gallery/15.jpg'),
        require('../../assets/service-gallery/16.jpg'),
        require('../../assets/service-gallery/17.jpg'),
        require('../../assets/service-gallery/18.jpg'),
        require('../../assets/service-gallery/19.jpg'),
        require('../../assets/service-gallery/20.jpg'),
        require('../../assets/service-gallery/21.jpeg'),
        require('../../assets/service-gallery/22.jpeg'),
        require('../../assets/service-gallery/23.jpeg'),
      ]}
    />
    <ServiceFAQs
      faqTitle={'FAQs'}
      faqSubTitle={'Got a question about piano moves?'}
      faqs={[
        {
          question: 'Is moving a piano expensive?',
          answer: 'It doesn\'t have to be. Just tell us what type of piano you have - Grand Piano, Upright Piano, or Digital Piano in the booking process - and we will bring all the tools to help you move it.'
        },
        {
          question: 'How do I move a piano?',
          answer: 'Let JJD help you to move your piano with the right tools. Don\'t try to move it yourself if you\'re worried about damaging ti. We have experienced professionals equipped with piano covers and lifts for this type of job.'
        },
        {
          question: 'Another company charged me £200 to move a piano, it that a good price?',
          answer: 'No, that is very expensive for a piano move - and that money probably didn\'t even go to the driver. We\'re sorry to hear that you\'ve most likely been ripped off.'
        },
        {
          question: 'Is moving a piano difficult?',
          answer: 'If you\'ve never done it before, we recommend using one of our experienced professionals. It\'s not rocket science, but you\'ll need the right equipment and experience to get it moved safely. Be aware that many companies will tell you it is a \'highly specialised\' job that requires \'years of training, knowledge and expertise\' - there most likely just fishing for more money.'
        }
      ]}
    />
    <BookOnlineContainer />
  </Layout>
);

export default PianoMoversPage;
